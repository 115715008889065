footer {
    color: #000;
    /* background: linear-gradient(to bottom, rgba(2, 2, 58, 0.7), rgba(2, 2, 58, 0.7)), 
                url('https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg');
    background-size: cover;
    background-position: center; */
    background-color: #f9f9f9;
    width: 100%;
    /* padding: 40px 20px; */
  }
  
  .foot-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .foot-one, .foot-two {
    display: flex;
    flex-wrap: wrap;
    /* flex: 1 1 50%; */
    margin: 0 auto;
  }
  
  .address, .phone, .email, .go-to, .support, .media {
    margin-left: 20px;
    margin-top: 50px;
    width: 200px;
  }
  
  .go-to p, .email p, .support p{
    display: block;
    width: max-content;
    padding-bottom: 2px;
    border-bottom: solid 1px transparent;
    transition: color 0.5s ease, border-bottom 0.5s ease;
}

.go-to p:hover, .support p:hover, .email p:hover {
    cursor: pointer;
    border-bottom-color: #000;
    transition: color 0.5s ease, border-bottom 0.5s ease;
}

  .foot-one h1, .foot-two h1 {
    margin-top: 0;
    line-height: 1.8;
    color: #000;
    font-weight:bold;
    font-size: 18px;
  }
  
  .foot-one p, .foot-two p {
    line-height: 1.5;
    font-weight:lighter;
    font-size: 14px;
  }

  .line {
    border: solid 1pt #6b6969;
    width: 80%;
    margin: 0 auto;
    margin-top: 60px;
  }
  
  .copy {
    color: #000;
    font-size: 12px;
    text-align: center;
    margin-top: 25px;
    padding-bottom: 10px;
  }
  
  .media-icon {
    display: flex;
    gap: 15px;
  }

  .media-icon img{
    background-color: white;
    border-radius: 5px;
  }
  
  .media-icon img:hover {
    transform: scale(1.3);
    transition: transform 0.3s ease;
    background-color: #cccaca;
  }
  
  a img {
    width: 20px;
  }
  
  a {
    display: inline-block;
    padding: 0;
    margin: 0;
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .foot-wrapper {
      flex-direction: column;
      padding-left: 0;
    }
  
    .address, .phone, .email, .go-to, .support, .media {
      margin-left: 0;
      width: 100%;
      text-align: center;
    }
  
    .media-icon {
      justify-content: center;
      margin-top: 10px;
    }
  
    .foot-one, .foot-two {
      flex: 1 1 100%;
      justify-content: center;
      margin-bottom: 20px;
    }
  
    .foot-wrapper h1 {
      font-size: 16px;
    }
  
    .foot-wrapper p {
      font-size: 12px;
    }
  
    .copy {
      margin-top: 20px;
      padding-bottom: 20px;
    }
  }
  
.services-container {
    text-align: center;
    padding: 40px;
    background-color: #f9f9f9;
  }
  
  .services-subtitle {
    font-size: 14px;
    color: #b22222;
    /* color: #f0a500; */
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .services-title {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin: 10px 0 40px;
  }
  
  .services-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .service-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 180px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .service-item:hover {
    transform: translateY(-5px);
    color: #f0a500;
  }
  
  .service-icon {
    font-size: 40px;
    color: #b22222;
    margin-bottom: 10px;
  }
  
  .service-title {
    font-size: 16px;
    font-weight: 600;
    color: #b22222;
  }

  /* .services-title{
    color: #fc5521;
  } */
  
  .service-style {
    background-color: #f9f9f9;
  }

.split-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 2rem; */
  max-width: 1050px;
  margin: 0 auto;
}

.text-content {
  max-width: 400px;
  flex: 1;
  padding-right: 2rem;
  padding-left: 2rem;
}

.text-content h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.text-content p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.text-content button {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.text-content button:hover {
  background-color: #0056b3;
}

.image-section {
  flex: 1;
  /* text-align: center; */
}

.image-section img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .split-section {
    flex-direction: column;
    text-align: center;
  }

  .text-content {
    padding-right: 0;
    margin-bottom: 2rem;
  }

  .image-section img {
    max-width: 80%;
  }
}
.contact-container {
    display: flex;
    gap: 20px;
    padding: 40px;
    background-color: #f5f5f5;
  }
  
  .left-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .section-title {
    text-transform: uppercase;
    font-size: 30px;
  
    color: #666;
    background-color: #f5f5f5;
    text-align: center;
    padding-top: 50px;
  }
  
  .heading {
    font-size: 36px;
    font-weight: bold;
    color: #333;
  }
  
  .description {
    font-size: 16px;
    color: #666;
  }
  
  .contact-options {
    display: flex;
    gap: 20px;
    margin-top: 50px;
  }
  
  .contact-option {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 250px;
    font-size: small;
    font-weight: lighter;
  }
  
  .contact-option h3{
    font-size: larger;
    font-weight:400;
  }

  .contact-option p{
    font-size: smaller;
  }
  
  .icon-wrapper {
    /* background-color: #ffa500; */
    border-radius: 50%;
    padding: 10px;
  }
  
  .icon {
    font-size: 20px;
    color: #fff;
  }

  .msg p:hover{
    color: #ffa500;
  }

  .right-section {
    /* border: solid 1px red; */
    flex: 1;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .row {
    display: flex;
    gap: 10px;
  }
  
  .input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .textarea {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 100px;
  }
  
  .submit-button {
    background-color: #ffa500;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover{
    background-color: #cf8802;
  }
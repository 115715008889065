.portfolio {
    padding: 40px 20px;
    text-align: center;
    background-color: #f3f3f3;
  }
  
  .portfolio-title {
    text-align: left;
    font-size: 3rem;
    margin-bottom: 30px;
    font-weight: bold;
    color: #fc5521;
  }

  .pagination-controls{
    margin-top: 50px;
  }

  .portfolio p {
    width: 400px;
    margin-bottom: 100px;
    /* justify-content:baseline; */
  }

  .welcome-title{
    text-align: left;
  }
  
  .project-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  
  .project-item {
    width: calc(30% - 40px);
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.6s ease;
  }
  
  .project-item img {
    width: 100%;
    height: auto;
  }
  
  .project-item h3 {
    font-size: 1.2rem;
    color: #333;
    margin: 10px 0;
    padding: 0 10px;
  }
  
  .project-item:hover {
    transform: scale(1.1);
  }
  
  .load-more {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #233985;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .load-more:hover {
    background-color: #f0a500;
  }
  
  @media (max-width: 768px) {
    .project-item {
      width: calc(45% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .project-item {
      width: calc(100% - 20px);
    }
  
    .portfolio-title {
      font-size: 1.5rem;
    }
  
    .load-more {
      width: 100%;
      padding: 15px;
      font-size: 1.2rem;
    }
  }
  
.about {
    background-color: white;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .about-title {
    text-align: center;
    color: #233985;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .about-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
  }
  
  .about-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 10px;
  }
  
  .about-text {
    color: black;
    max-width: 600px;
    margin-left: 30px;
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .about-wrapper {
      flex-direction: column;
      text-align: center;
    }
  
    .about-text {
      margin-left: 0;
      margin-top: 20px;
    }
  
    .about-image img {
      max-width: 100%;
    }
  }
  
.video-gallery-container {
    text-align: center;
    margin-top: 20px;
    padding: 40px;
    /* background-color: #ffffff; */
  }
  
  .gallery-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .videos {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .video-item {
    width: 300px;
    text-align: center;
  }
  
  .video-frame {
    width: 100%;
    height: 170px;
    border-radius: 8px;
    border: none;
  }
  
  .video-title {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
  }
  
  .view-all-button {
    padding: 10px 20px;
    background-color: #b22222;
    color: #ffffff;
    font-size: 14px;
    border: solid 1px transparent;
  }
  
  .view-all-button:hover {
    background-color: #fff;
    border: solid 1px #b22222;
    color: #b22222;
    transition: background-color 0.6s ease;
  }
  
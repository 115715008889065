.nav-Bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: linear-gradient(to bottom, rgba(2, 2, 58, 0.5), rgba(2, 2, 58, 0.5)),
              url('https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg');
  /* backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px); */
  /* border: 1px solid rgba(255, 255, 255, 0.3); */
  /* border-radius: 8px; */
  color: #fff;
  position:sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

/* hhh */

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-Bar .logo {
  width: 20vh;
  height: 7vh;
  margin-left: 20px;
  /* padding: 20px; */
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-left: auto;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.4s;
}

.hamburger .bar.open:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger .bar.open:nth-child(2) {
  opacity: 0;
}

.hamburger .bar.open:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1rem;
  padding: 0;
  margin-right: 100px; 
  transition: max-height 0.4s ease;
}

.nav-links li {
  margin-left: 15px;
}

.nav-links li a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.5s ease, border-bottom 0.5s ease;
}

.nav-links li a:hover {
  color: #f0a500;
  border-bottom: solid 1px #f0a500;
}

.nav-links li a:focus {
  color: #f0a500;
  border-bottom: solid 1px #f0a500;
}

@media (max-width: 768px) {
  .nav-Bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .hamburger {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
    max-height: 0;
    overflow: hidden;
  }

  .nav-links.open {
    max-height: 300px;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .nav-links li a {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .nav-Bar .logo {
      width: 5vh;
      height: 6vh;
    /* width: 120px; */
  }

  .nav-links li a {
    font-size: 1rem;
  }
}

.hero {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    animation: slideBackground 9s infinite;
    z-index: 1;
  }
  
  .hero-content {
    position: relative;
    z-index: 2;
  }
  
  .hero h1 {
    font-size: 4rem;
    margin: 0;
    padding: 0;
    font-weight: bolder;
    font-style: italic;
  }
  
  .hero p {
    font-size: 1.5rem;
    font-weight: bolder;
    font-style: italic;
    margin: 10px 0 0 0;
  }
  
  @keyframes slideBackground {
    0% {
      background-image: url('https://res.cloudinary.com/dtqrzl86y/image/upload/v1724334978/floorplan_dhmpcu.jpg');
    }
    33.33% {
      background-image: url('https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319579/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.37.14_9b63033b_djwken.jpg');
    }
    66.66% {
      background-image: url('https://res.cloudinary.com/dtqrzl86y/image/upload/v1724334978/stairs_klg4so.jpg');
    }
    100% {
      background-image: url('https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg');
    }
  }
  
  .hero-content {
    position: relative;
    z-index: 2;
    opacity: 0; /* Start with the content hidden */
    transform: translateY(20px); /* Slightly move the content down */
    animation: fadeInUp 2s ease-in-out 1s forwards; /* Animate the content */
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px); /* Start from slightly below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* Move to the original position */
    }
  }
  
  .hero h1 {
    margin: 0;
    padding: 0;
    font-size: 4rem;
    animation: textHover 0.5s ease-in-out;
  }
  
  .hero p {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    margin-top: 10px;
    animation: textHover 0.5s ease-in-out;
  }
  
  /* Add hover effect for interactivity */
  .hero h1:hover, .hero p:hover {
    animation: bounce 0.6s ease; /* Bounces slightly on hover */
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px); /* Bounces up */
    }
  }
  
  @keyframes textHover {
    0% {
      opacity: 0;
      transform: scale(0.95); /* Start smaller */
    }
    100% {
      opacity: 1;
      transform: scale(1); /* Grow to full size */
    }
  }
  